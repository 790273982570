import { Container, Dialog, DialogContent, DialogTrigger, Heading, HorizontalRule, Picture, Section } from '@troon/ui';
import { Icon } from '@troon/icons';
import { NoHydration } from 'solid-js/web';
import { AccessProducts } from '../../components/access-products';
import { AccessLandingPage } from './(access)';
import type { RouteDefinition } from '@solidjs/router';

export default function AccessGolfPass() {
	return (
		<AccessLandingPage golfPass>
			<Container>
				<div id="golf-pass" class="grid grid-cols-1 gap-x-16 gap-y-8 md:grid-cols-2 lg:gap-x-36">
					<div class="flex flex-col justify-center gap-8">
						<Section>
							<Picture src={golfpassLogo} alt="GolfPass+" sizes={[[455, 114]]} width={227} height={57} />
							<Heading as="h2">Unlock a year of GolfPass+ with Troon Access+</Heading>
							<p>
								GolfPass+ members save hundreds every year on tee times and live golf tournament coverage. New members
								get benefits to help them play more and save more like waived booking fees, Tee Time Protection, and $10
								monthly tee time credits (that’s $120 a year). Plus, get on-demand lessons, access to Golf Channel
								classics and GolfPass originals, like The Conor Moore show and Ask Rory. Plus, members get access to
								stream live sporting events on Peacock, along with news, exclusive shows, hit movies, and more!
							</p>
							<div>
								<Dialog key="access-products-golfpass">
									<DialogTrigger appearance="primary">
										Get started <Icon name="arrow-right-md" />
									</DialogTrigger>
									<DialogContent header="Choose your Troon Access" headerLevel="h3" autoWidth>
										<AccessProducts golfPass />
									</DialogContent>
								</Dialog>
							</div>
						</Section>
					</div>
					<NoHydration>
						<div class="flex flex-col justify-center gap-8">
							<Section class="rounded bg-brand-100 p-4 md:p-6">
								<Heading as="h3" size="p" class="uppercase">
									Included with GolfPass+
								</Heading>
								<ul class="flex flex-col gap-2">
									<li class="flex flex-row items-baseline gap-2">
										<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
										Waived fees for 10 tee time bookings (up to 4 players per booking)
									</li>
									<li class="flex flex-row items-baseline gap-2">
										<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
										$10 monthly tee time credits ($120 per year)
									</li>
									<li class="flex flex-row items-baseline gap-2">
										<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
										Tee Time Protection on up to 10 bookings (up to 1 hour prior to tee time, for any reason)
									</li>
									<li class="flex flex-row items-baseline gap-2">
										<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />1 year of Peacock included
										($79.99 value)
									</li>
									<li class="flex flex-row items-baseline gap-2">
										<Icon name="check" class="relative top-2 shrink-0 text-xl text-brand" />
										Earn and redeem GolfPass Points for discounted or free tee times
									</li>
								</ul>
							</Section>
						</div>
					</NoHydration>
				</div>
			</Container>
			<Container>
				<HorizontalRule />
			</Container>
		</AccessLandingPage>
	);
}

const golfpassLogo =
	'https://images.ctfassets.net/rdsy7xf5c8dt/39shrMUGcPa9lWDXsfZP7i/ed6d8f4d50f0f368a42722c1a9604bbc/golf-pass-plus-logo.png';

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
